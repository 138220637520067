<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                非凸科技与国信证券、富国基金，共探私募机构行业生态
              </p>
              <div class="fst-italic mb-2">2023年7月14日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >新闻</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >国信证券</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <section class="mb-5">
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/46/01-致辞.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    近年来，我国私募行业快速发展，带来了巨大的变革和机遇。为促进行业深度交流，7月8日，国信证券联合富国基金在上海举办私募机构分享会，非凸科技受邀出席，与在场嘉宾共同探讨私募机构生态。
                  </p>
                  <p>
                    作为具有全业务牌照的全国性大型综合类证券公司，国信证券聚焦私募机构各类服务诉求，在交易服务、资金渠道、投研服务、托管外包、优质券源、渠道销售等各环节提供全方位的服务支撑，以满足私募基金全生命周期的综合金融服务需求。除此之外，国信证券一直秉承着专业、务实的服务理念，持续加大研发投入与技术创新，让金融科技真正服务于业务发展。目前，国信证券已与非凸科技达成战略合作，非凸智能算法即将上线。未来，双方也将共同付诸实践，赋能行业高质量发展。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/46/02-谢分分.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    为深度赋能私募基金发展，提供更高质量、更契合的服务，国信证券推出了GTrade+私募综合服务体系。国信证券机构业务中心谢分分表示，“国信GTrade+能够面向高频交易机构、个性化策略交易、股权/证券投资私募，分别提供全链路低延时解决方案、策略交易解决方案、智能算法解决方案。国信GTrade+除了性能稳定、运行稳定、部署灵活等优势外，覆盖了多种算法交易场景，打造了一体化算法交易体验。”
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/46/03-金泽宇博士.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    富国基金在ETF领域深耕多年，旨在为投资者打造多样化、多策略、全谱系的ETF资产配置工具箱。会上，富国基金1000ETF基金经理金泽宇博士详细介绍了富国基金ETF生态圈及合作模式。1000ETF的成功发行，是富国基金多年来在被动投资方面的持续投入和经验积累。富国部分行业主题ETF并未选择传统的行业指数作为跟踪基准，而是优选兼具选股功能的指数，传承了“稳健增强”的基因；另外，富国行业主题ETF的覆盖方向十分全面且多样化，布局时点较为领先，与指数基金业务一脉相承。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/46/04-杨丽华.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    就此，国信经济研究所金融工程资深分析师杨丽华表示，“近几年，由于结构化行情比较明显，主题和行业ETF产品发展较快，到2023年2季度末，ETF规模已超过1.5亿，其中，规模/主题/行业ETF三者的占比已超过80%。随着ETF的快速发展，使用ETF工具来实现组合配置的人越来越多，且能实现快速的策略配置，比如FOF基金，2022年底ETF的配置规模超过256亿。未来，ETF仍有较大的发展空间。”
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/46/05-郑媛姿.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    最后，非凸科技CBO郑媛姿就“私募产品算法交易应用”主题进行了分享。她表示，“现阶段，非凸科技主要客群以私募基金为主，在获得市场高度认可的同时，也收到了来自市场的许多需求，比如ETF算法，期货算法等。对于管理人来说，非凸算法能够解决交易人员不足、降低接入成本、开发与管理成本、策略容量不足等问题；对于券商来说，非凸算法能够提升客户交易体验与客户黏性，盘活大量沉睡资产，以及为客户投资收益增加条件等。未来，我们在追求极致的稳定与高效的同时，也希望通过平台交流互动与定向资源引荐，不断推动数智交易行业提速发展。”
                  </p>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News46",
};
</script>

<style></style>
